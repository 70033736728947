import { model } from '@telekomconsalting/dex-guru-model'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { FULL_HISTORY_BUTTON_THRESHOLD } from '../../config/settings'
import { getTradeBarsData } from '../../helpers/tableHelpers'
import useElementHeight from '../../hooks/useElementHeight'
import { FullSidebarsModifier, TokenWithApprovalContext, TransactionModel } from '../../model'
import { State } from '../../reducers'
import ButtonsGroup, { ButtonsGroupVariant } from '../ButtonsGroup'
import { Heading } from '../Heading'
import Message, { MessageIcons } from '../Message'
import calculatePoolActivityRowsToShow from '../PoolActivity/calculatePoolActivityRowsToShow'
import SidebarHistoryHeader from '../Sidebar/SidebarHistoryHeader'
import { RefreshIndicator } from './RefreshIndicator'

export interface RowProps {
  modifier: FullSidebarsModifier
  classAnimated: string
  key: string
  countNewRowActivity?: number
  option?: number
  activeRow?: string
  account?: model.Address | null
}

export interface FilterButton {
  label: string
  onClick: () => Promise<void>
}

interface TableWithFiltersProps<T extends TransactionModel> {
  renderRow: (props: RowProps & T) => React.ReactElement | null
  token: TokenWithApprovalContext | undefined
  title: string
  modifier: FullSidebarsModifier
  filterButtons?: ButtonsGroupVariant<string>[]
  currentData?: T[]
  prevData?: T[]
  noDataMessage: string
  isLoadingRows?: boolean
  activeRow?: string
  suffixContent?: React.ReactElement | React.ReactElement[] | null
  fullHistoryButtonThreshold?: number
  refreshTimestamp?: number
  refreshed?: boolean
  onClickRow?: (wallet?: string, txnId?: string, poolAddress?: model.Address) => void
  onChangeRowsToShow?: (amount: number) => void
  account?: model.Address | null
}

// There is no easy way to have it FC<TableFullWithFiltersProps<T>> https://dev.to/janjakubnanista/a-peculiar-journey-to-a-generic-react-component-using-typescript-3cm8
export default function TableWithFilters<T extends TransactionModel>(
  props: TableWithFiltersProps<T>
): React.ReactElement | null {
  const historyContentBodyRef = useRef<HTMLDivElement>(null)
  const [columnRow, setColumnRow] = useState({ prev: 0, current: 0 })
  const [animate, setAnimate] = React.useState(true)
  const [activeFilter, setActiveFilter] = useState(
    props.filterButtons ? props.filterButtons[0]?.value : ''
  )
  const [option, setOption] = useState<number>(0)
  const { isMobile } = useSelector((state: State) => state)

  const { modifier, fullHistoryButtonThreshold } = props
  const currentData = props.currentData || []
  const prevData = props.prevData || []

  useEffect(() => {
    setActiveFilter(props.filterButtons ? props.filterButtons[0]?.value : '')
  }, [props.filterButtons, props.token?.id])

  const _getAnimation = (rowData: T): string => {
    if (prevData.length > 0) {
      const prevOldestTimestamp = prevData[0].timestamp
      if (rowData.timestamp > prevOldestTimestamp) {
        return 'fade-enter-done'
      } else {
        return ''
      }
    }
    return ''
  }

  const onChangeOption = (value: number): void => {
    setOption(value || 0)
  }

  const tradeBarData = getTradeBarsData(currentData)

  const dataToRender = currentData?.map((e: T, i: number) => (
    <props.renderRow
      modifier={modifier}
      classAnimated={_getAnimation(e)}
      key={`${e.transactionAddress}-${i}`}
      option={option}
      tradeBarData={tradeBarData}
      activeRow={props.activeRow}
      onClickRow={props.onClickRow}
      account={props.account}
      {...e}
    />
  ))
  const [componentHeights, setComponentHeights] = useState<{
    contentHeight?: number
    theadHeight?: number
  }>({})

  const handleChangeTHeadHeight = (theadHeight: number): void =>
    setComponentHeights((curComponentHeights) => ({ ...curComponentHeights, theadHeight }))

  const handleContentHeightChange = (contentHeight: number): void =>
    setComponentHeights((curComponentHeights) => ({ ...curComponentHeights, contentHeight }))

  useElementHeight<HTMLDivElement>(historyContentBodyRef, handleContentHeightChange)
  const { onChangeRowsToShow } = props

  useEffect(() => {
    if (componentHeights.contentHeight === undefined) {
      return
    }
    const rowsToShow = calculatePoolActivityRowsToShow({
      contentHeight: componentHeights.contentHeight,
      theadHeight: componentHeights.theadHeight || 0,
    })
    if (onChangeRowsToShow) {
      onChangeRowsToShow(rowsToShow)
    }
  }, [componentHeights, onChangeRowsToShow])

  const renderTable = (): JSX.Element => (
    <>
      <div
        className={classNames(
          `history-content__table history-content__table--${modifier} history-content__table--compact`
        )}>
        <SidebarHistoryHeader
          onChangeTHeadHeight={handleChangeTHeadHeight}
          modifier={modifier}
          option={option}
          onChangeOption={onChangeOption}
          isHide={dataToRender.length === 0 && !props.isLoadingRows}
        />
        {dataToRender.length ? (
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={String(animate)}
              addEndListener={(node: HTMLElement, done: () => void): void => {
                node.addEventListener('transitionend', done, false)
              }}
              classNames="fade">
              <div className="history-content__tbody">{dataToRender}</div>
            </CSSTransition>
          </SwitchTransition>
        ) : (
          ''
        )}
      </div>
      {!dataToRender.length && props.noDataMessage && (
        <>
          {props.isLoadingRows ? (
            <div className="history-content__nodata">
              <div className="text">{props.noDataMessage || ''}</div>
            </div>
          ) : (
            <div className="history-content__empty history-content__empty--compact">
              <Message
                text={props.noDataMessage}
                type="empty"
                icon={MessageIcons.warning}
                isShowMascot={false}
              />
            </div>
          )}
        </>
      )}
    </>
  )

  const renderSuffix = (): React.ReactElement | React.ReactElement[] | null => {
    return dataToRender.length >= (fullHistoryButtonThreshold || FULL_HISTORY_BUTTON_THRESHOLD) &&
      props.suffixContent &&
      props.filterButtons &&
      ((modifier === 'trading-history' && activeFilter === props.filterButtons[0]?.value) ||
        modifier === 'pool-activity')
      ? props.suffixContent
      : null
  }

  const handleFilterChange = (value: string): void => {
    if (value === activeFilter) {
      return
    }

    setActiveFilter(value)
    const current =
      props.filterButtons && props.filterButtons.length
        ? props.filterButtons.findIndex((item) => item.value === value)
        : 0
    setColumnRow({
      prev: columnRow.current,
      current: current,
    })
    setAnimate(!animate)
  }

  return (
    <>
      <div
        className={classNames(
          'history-content',
          'history-content--compact',
          modifier ? `history-content--${modifier}` : '',
          columnRow.current > columnRow.prev ? 'swipe--right' : 'swipe--left'
        )}>
        <div className="history-content__header">
          <Heading size="xs">{props.title}</Heading>
          <RefreshIndicator timestamp={props.refreshTimestamp} blink={props.refreshed} />
          <div className="history-content__actions">
            {!!props.filterButtons && (
              <ButtonsGroup
                value={activeFilter}
                buttons={props.filterButtons}
                onChange={handleFilterChange}
                disabled={props.isLoadingRows}
              />
            )}
          </div>
        </div>
        <div className="history-content__body" ref={historyContentBodyRef}>
          {renderTable()}
          {!isMobile && renderSuffix()}
        </div>
      </div>
    </>
  )
}
