import React, { FC } from 'react'

import Button from '../buttons/Button'
import CloseButton from '../buttons/CloseButton'
import { Card } from '../Card'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal'
import Typography from '../Typography/Typography'
import { EmojiHintsByType } from './helpers'

const ModalEmojiDetails: FC<{
  onClose: () => void
  modifier: string
}> = ({ onClose, modifier }) => {
  const emojiHints = EmojiHintsByType[modifier]

  if (!emojiHints?.hints) {
    return null
  }

  const hints = Object.keys(emojiHints.hints).map((key) => emojiHints.hints[key])

  return (
    <Modal className="modal--details" testId="emoji-details">
      <ModalHeader>
        <Typography variant="h4" title="Market Activity" className="modal__title" />
        <CloseButton onClick={onClose} className="modal__close" />
      </ModalHeader>
      <ModalBody>
        {emojiHints.description && (
          <div className="modal__description">{emojiHints.description}</div>
        )}
        <Card>
          <dl className="emoji-definitions">
            {hints.map((hint, idx) => {
              return (
                <React.Fragment key={idx}>
                  <dt className="emoji-definitions__icon">
                    <div className="icon">{hint.icon}</div>
                  </dt>
                  <dd className="emoji-definitions__text">{hint.text}</dd>
                </React.Fragment>
              )
            })}
          </dl>
        </Card>
      </ModalBody>
      <ModalFooter>
        <div className="row row--justify-center">
          <div className="cell cell--auto">
            <Button
              caption="Close"
              buttonSize="lg"
              className="modal__action button--accept"
              onClick={onClose}
            />
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalEmojiDetails
