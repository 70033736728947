import { ReactComponent as Bot } from 'app-images/icons/emoji/bot.svg'
import { ReactComponent as Elephant } from 'app-images/icons/emoji/elephant.svg'
import { ReactComponent as Rooster } from 'app-images/icons/emoji/rooster.svg'
import { ReactComponent as Shark } from 'app-images/icons/emoji/shark.svg'
import { ReactComponent as Tiger } from 'app-images/icons/emoji/tiger.svg'
import { ReactComponent as Turtle } from 'app-images/icons/emoji/turtle.svg'
import { ReactComponent as Whale } from 'app-images/icons/emoji/whale.svg'
import { ReactElement } from 'react'

type EmojiHint = { icon: ReactElement; text: string | ReactElement }
type EmojiHintRecords = Record<string, EmojiHint>

export const EmojiTradingHistoryHints: EmojiHintRecords = {
  turtle: {
    icon: <Turtle />,
    text: <>Casual Trader with $10k-$100k of a&nbsp;trading volume in the last 30&nbsp;days</>,
  },
  shark: {
    icon: <Shark />,
    text: <>Active Trader with $100k-$500k of a&nbsp;trading volume in the last 30&nbsp;days</>,
  },
  whale: {
    icon: <Whale />,
    text: <>Heavy Trader with $500k+ of a&nbsp;trading volume in the last 30&nbsp;days</>,
  },
  bot: {
    icon: <Bot />,
    text: <>Trader with 1000+ TXs in the last 30 days. Most likely a bot</>,
  },
}

export const EmojiPoolActivityHints: EmojiHintRecords = {
  rooster: {
    icon: <Rooster />,
    text: (
      <>
        During the last 30&nbsp;days, this Liquidity Provider continuously has had $10k-$100k locked
        inside different liquidity pools across all supported chains.
      </>
    ),
  },
  tiger: {
    icon: <Tiger />,
    text: (
      <>
        During the last 30&nbsp;days, this Liquidity Provider continuously has had $100k-$500k
        locked inside different liquidity pools across all supported chains.
      </>
    ),
  },
  elephant: {
    icon: <Elephant />,
    text: (
      <>
        During the last 30&nbsp;days, this Liquidity Provider continuously has had $500k+ locked
        inside different liquidity pools across all supported chains.
      </>
    ),
  },
}

export const EmojiHintsByType: Record<
  string,
  { description: string | ReactElement; hints: EmojiHintRecords }
> = {
  'pool-activity': {
    description: (
      <>
        If we have enough data about an account, we will add an icon to the transaction indicating
        the type of liquidity provider.
      </>
    ),
    hints: EmojiPoolActivityHints,
  },
  'trading-history': {
    description: (
      <>
        If we have enough data about the wallet that a trader used, we will add an icon to the
        transaction indicating the type of trader.
      </>
    ),
    hints: EmojiTradingHistoryHints,
  },
}
