import { FC } from 'react'

import BlinkIndicator from '../BlinkIndicator'
import TimeAgo from '../TimeAgo/TimeAgo'

export const RefreshIndicator: FC<{
  timestamp?: number
  blink?: boolean
  tooltipPlace?: 'top' | 'bottom'
}> = ({ timestamp, tooltipPlace = 'top', blink = false }) => {
  if (!timestamp) {
    return null
  }

  return (
    <div className="history-content__indicator">
      <div
        data-tip="Time to update transactions"
        data-class="short"
        data-for="app-tooltip"
        data-place={tooltipPlace}
        className="history-indicator">
        <BlinkIndicator state={!blink ? 'good' : undefined} speed={blink ? 'slow' : undefined} />
        <TimeAgo timestamp={timestamp} direction="before" />
      </div>
    </div>
  )
}
